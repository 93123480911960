/**
 * CSS Naming Conventions
 * https://medium.com/@drublic/css-naming-conventions-less-rules-more-fun-12af220e949b
 *
 * Code splitting pattern
 * http://bensmithett.com/smarter-css-builds-with-webpack/
 */

@import "config/mixins";

*, *:before, *:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
   //TODO remove after redesign pages from previous site
  font-family: $font-family-base;
  line-height: $line-height-base;

  color: $color-text;
  background-color: $color-background;

  &.is-dark {
    color: $color-dark-text;
    background-color: $color-dark-background;
  }

  @include respond-to(mobile) {
    overflow: visible !important;
  }
}

.max-height {
  height: 100%;
}

a, a:visited {
  color: inherit;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-weight: normal;
}

p {
  font-weight: inherit;
}

.only-desktop {
  @include respond-to(mobile) {
    display: none !important;
  }
}

.only-mobile {
  @include respond-to(md) {
    display: none !important;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
