/* Font sizes: 8, 12, 15, 18, 20, 24 */

$color-blue: #042655;

$color-text: $color-blue;
$color-background: white;

$color-dark-text: white;
$color-dark-background: black;


$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-family-mono: PFDINMonoPro, Courier, mono;
$font-size-base: 16px;
$line-height-base: 1.2;

$page-padding: 50px;

$page-desktop-md: 900px;
$page-desktop-md-min: 980px;
$page-desktop-hd: 1405px;

$modalZIndex: 999;
