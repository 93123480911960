@import "config/mixins";

@font-face {
    font-family: 'sweatcoin';
    src: url('~assets/icons/sweatcoin.woff2') format('woff2'),
         url('~assets/icons/sweatcoin.woff')  format('woff'),
         url('~assets/icons/sweatcoin.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    @include icon;
}

.icon-logo:before {
  content: "\e900";
}
.icon-logo-full:before {
  content: "\e901";
}
.icon-ig:before {
  content: "\e911";
}
.icon-fb:before {
  content: "\e902";
}
.icon-tw:before {
  content: "\e903";
}
.icon-in:before {
  content: "\e904";
}
.icon-swc:before {
  content: "\e905";
}
.icon-swc-thin:before {
  content: "\e906";
}
.icon-apple:before {
  content: "\e907";
}
.icon-arrow-down:before {
  content: "\e908";
}
.icon-arrow-left:before {
  content: "\e909";
}
.icon-arrow-right:before {
  content: "\e90a";
}
.icon-android:before {
  content: "\e90b";
}
.icon-fb-inverted:before {
  content: "\e90c";
}
.icon-tw-inverted:before {
  content: "\e90d";
}
.icon-arrow-up:before {
  content: "\e90e";
}
.icon-appstore:before {
  content: "\e90f";
}
.icon-googleplay:before {
  content: "\e910";
}
