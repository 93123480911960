@import "config/mixins";
@import "icons";

.company-join-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__mood {
    background: url(~assets/mood_bg.jpg) no-repeat center bottom;
    background-size: cover;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    &__gradient {
      background: linear-gradient(135deg, #032555, #87CAB5);
      mix-blend-mode: hard-light;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding: 24px;
    color: white;
    text-align: center;
  }

  &__top, &__cta, &__bottom {
    flex: 1;
  }

  &__top {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__bottom {
    margin-top: 42px;
    text-align: left;

    &__campaign-title, &__about{
      font-size: 20px;
      color: #FFFFFF;
      line-height: 25px;
      letter-spacing: -0.5px;
    }

    &__join-text {
      margin-top: 10px;
      font-size: 17px;
      color: #FFFFFF;
      line-height: 26px;
      letter-spacing: -0.41px;
    }

    &__about {
      margin-top: 24px;
    }

    &__campaign-description {
      margin-top: 10px;
      font-size: 17px;
      color: #FFFFFF;
      letter-spacing: -0.41px;
      line-height: 26px;
      white-space: pre-wrap;
    }
  }

  &__heading {
    margin: 24px 0 32px;
    font-family: $font-family-mono;
    font-size: 24px;
    line-height: 1.4;
    letter-spacing: -0.5px;

    @media (min-width: 375px) {
      font-size: 28px;
    }
  }

  &__social {
    margin-top: 24px;

    &__avatar, &__logo {
      position: relative;
      display: inline-block;
      width: 100px;
      height: 100px;
      font-size: 100px;
      line-height: 100px;
      vertical-align: top;
    }

    &__logo {
      z-index: 1;
    }

    p {
      margin-top: 24px;
      font-size: 20px;
    }
  }

  &__action {
    user-select:  none;

    a, a:visited {
      color: #032555;
    }

    &__link {
      display: inline-block;
      padding: 14px 50px 17px;
      border-radius: 9px;
      background: #FFFFFF;

      font-size: 20px;
      font-weight: 500;
      color: #032555;
      letter-spacing: -0.38px;
      text-align: center;
      text-decoration: none;
      line-height: 25px;
      vertical-align: middle;

      cursor: pointer;
    }
  }
}
