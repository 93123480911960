@import "config/mixins";

@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;

  background-color: $color-text;

  .is-dark & {
    background-color: $color-dark-text;
  }
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  opacity: 1.0;

  box-shadow: 0 0 10px $color-text, 0 0 5px $color-text;

  .is-dark & {
    box-shadow: 0 0 10px $color-dark-text, 0 0 5px $color-dark-text;
  }

  -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  margin-left: -12px;
  margin-top: -12px;
}

#nprogress .spinner-icon {
  width: 24px;
  height: 24px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-radius: 50%;

  border-top-color: $color-text;
  border-left-color: $color-text;

  .is-dark & {
    border-top-color: $color-dark-text;
    border-left-color: $color-dark-text;
  }

  -webkit-animation: nprogress-spinner 400ms linear infinite;
          animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

