@import "config/mixins";

.page-control {
  display: inline-block;
  vertical-align: top;

  &__page {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 6px;
    margin-right: 6px;
    border: 1px solid transparent;
    border-color: inherit;
    border-radius: 50%;

    vertical-align: top;
    opacity: 0.3;
    cursor: pointer;

    transition: opacity .2s, border-width .2s;

    @include respond-to(md) {
    }

    &:hover, &.is-selected {
      opacity: 1;
      border-width: 5px;
    }


  }
}
