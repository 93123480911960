@import "config/mixins";

.availability {
  &__content {
    font-size: 15px;
    text-align: left;

    @include respond-to(md) {
      width: 500px;
      margin-left: auto;
      margin-right: auto;

      font-size: 18px;
    }
  }

  &__widget {
    @include respond-to(md) {
      width: 700px;
      margin-left: auto;
      margin-right: auto;
    }

    &__link {
      text-align: center;
      @include respond-to(mobile) {
        max-width: 400px;
        margin: {
          left: auto;
          right: auto;
        }
      }

      &__reward {
        position: relative;
        display: inline-block;
        padding-right: 60px;
        margin-top: 5px;
        margin-bottom: 25px;

        font-size: 13px;
        text-align: left;
        font-weight: 500;

        strong {
          position: absolute;
          right: 0;
          top: 50%;

          font-size: 36px;
          font-weight: 400;
          font-family: $font-family-mono;

          transform: translateY(-50%);

          i {
            vertical-align: baseline;
            opacity: 0.45;
          }
        }
      }

      &__download {
        display: inline-block;
        margin-left: 25px;
        margin-right: 25px;

        vertical-align: top;
        text-align: center;

        em {
          font-size: 12px;
          font-style: normal;
          opacity: 0.5;
        }
      }
    }

    &__sms {
      display: none;
      margin-top: 35px;

      font-size: 15px;
      text-align: left;

      @include respond-to(md) {
        flex-grow: 1;
        flex-shrink: 1;
        margin-top: 0;
        margin-left: 25px;

        font-size: 18px;
        line-height: 1.1;
      }
    }

    &__form {
      max-width: 380px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.is-column &__widget {
    @include respond-to(desktop) {
      display: block;
      width: auto;
    }

    &__sms {
      @include respond-to(desktop) {
        margin-left: 0;

        p {
          max-width: 350px;
          margin-bottom: 1em;

          line-height: 1.35;
        }
      }
    }

    &__link {
      @include respond-to(desktop) {
        margin-left: -40px;
        text-align: left;
      }

      &__download {
        @include respond-to(desktop) {
          margin-left: 40px;
          margin-right: 0;
          margin-bottom: 25px;
        }
      }
    }

    &__form {
      @include respond-to(desktop) {
        width: auto;
        max-width: 100%;
        margin: 0;

        .invite-form p {
          margin-left: 0;
          margin-right: 0;
          text-align: left;
        }
      }
    }
  }


}
